<template>
  <div>
    <div class="config-container">
      <div class="config-item">
        <p>语言本地化转换器</p>
        <p><a href="/game-scheme/config">快捷入口：标准配置转换器</a></p>
      </div>
      <div class="config-item">
        <input type="file" id="config" name="config" onclick="this.value=null;"
               @change="convertConfig">
      </div>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import ExcelJS from 'exceljs';

export default {
  name: 'LocalLanguage',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  created() {},
  methods: {
    // Excel 转 JSON
    convertConfig(event) {
      const file = event.target.files[0];
      if (file.name.search(/.xlsx$/) < 0 && file.name.search(/.xls$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then((excel) => {
          // 常数
          let nowLine = -1;
          const headerLine = 1;
          let headers = [];
          let result = JSON.parse('{}');
          const skips = ['module', 'tip', 'id'];

          excel.worksheets.map((sheet) => {
            headers = [];
            sheet.eachRow((row) => {
              nowLine++;
              if (headerLine > nowLine) {
                return;
              }

              if (headerLine === nowLine) {
                row.values.map((data) => {
                  headers.push(this.getText(data));

                  if (skips.includes(this.getText(data))) {
                    return data;
                  }
                  result[this.getText(data)] = {};
                  return data;
                });
                console.info('Header', headers);
                return;
              }

              let i = 1;
              row.values.map((data) => {
                i++;
                if (skips.includes(headers[i - 2])) {
                  return data;
                }
                const val = this.getText(row.values[i - 1]);
                if (val != null) {
                  result[headers[i - 2]][row.values[1]] = val;
                }
                return data;
              });
            });

            return sheet;
          });

          console.info(result);
          result = JSON.stringify(result);

          // 创建隐藏的可下载链接
          const eleLink = document.createElement('a');
          eleLink.download = 'i18.json';
          eleLink.style.display = 'none';
          // 字符内容转变成blob地址
          const blob = new Blob([result]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击并移除
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
        });
      };
      reader.readAsArrayBuffer(file);
      return true;
    },

    // 获取实际 Text
    getText(cell) {
      if (cell === undefined) {
        return null;
      }
      let text = null;
      if (cell.result) {
        return cell.result ? cell.result : null;
      }
      if (cell.richText) {
        cell.richText.map((value) => {
          if (value.text === '\n' || value.text === '\r' || value.text === '\r\n') {
            text += '\\n';
          } else {
            text += value.text.replaceAll('\n', '\\n');
          }
          return value || null;
        });
        return text || null;
      }
      if (typeof cell === 'string') {
        return cell.replaceAll('\n', '\\n');
      }
      return cell || null;
    },

    // excel k:v 转化器
    keyToValue(configSheet, parentName) {
      let result = '';
      configSheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          result += `\n"${parentName}": {`;
        }

        let i = 1;

        result += `"${this.getText(row.values[i++])}": "${this.getText(row.values[i++])}"`;

        if (configSheet.getRow(rowNumber + 1).values.length) {
          result += ', ';
        } else {
          result += '}';
        }
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-container {
  display: flex;
  justify-content: flex-start;
}
.config-item {
  flex: 0 1 20%;
  a {
    color: cornflowerblue;
    text-decoration: underline;
  }
  a:hover {
    color: cornflowerblue;
    text-decoration: none;
  }
}

.board-container {
  width: 470px;
  height: 470px; /* height given for illustration */
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.board-item {
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid black;
  margin: 4px;
  flex: 0 1 calc(20% - 10px);
}

a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

/* 模态框 */
.overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: relative;
    width: 600px;
    max-width: 80%;
    background: white;
    border-radius: 8px;
    padding: 1em 2em;
    .modal-close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: grey;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
.overlay:target {
  visibility: visible;
}
</style>
